<template>
  <div class="alertsDashboard">
    <vue-scroll :ops="ops">
      <div class="alertsDashboard__header">
        <div class="alertsDashboard__headerLeft">
          <div class="alertsDashboard__title">Route alerts: charters</div>
          <div
            class="alertsDashboard__button js-open-alert-form"
            :class="{'hideAlertBtn' : hideAlertBtn}"
            @click="showForm">
            <span class="text">Add route</span>
          </div>
        </div>
        <router-link class="signUpDashboard__button" to="" v-if="dashboardType === 'admin'">
          <span
            @click="exportToExcel()"
            class="text">Export</span>
        </router-link>
      </div>
      <div class="alertsDashboard__content">

        <div
          class="alertsDashboard__form js-alert-form"
          :class="{'open' : formOpened}"
          ref="alertForm"
        >
          <form
            class="form form--addAlert whitePicker"
            :class="{'round' : type === 'round trip'}"
            @submit.prevent="submitHandler"
          >
            <div class="form__left">
              <div class="form__labelGroup form__labelGroup--type">
                <label class="form__label form__label--radio">
                  <input
                    class="form__radio js-changeWay"
                    type="radio"
                    v-model="type"
                    value="one way"
                  />
                  <span class="form__radio-box">One way</span>
                </label>
                <label class="form__label form__label--radio">
                  <input
                    class="form__radio js-changeWay"
                    type="radio"
                    v-model="type"
                    value="round trip"
                  />
                  <span class="form__radio-box">Round trip</span>
                </label>
              </div>
              <div class="form__labelGroup form__labelGroup--route">
                <label class="form__label form__label--text">
                  <div class="form__caption">Departure</div>
                  <div class="form__field-wrapper">
                    <div class="form__icon">
                      <SvgIcon name="departures"/>
                    </div>
                    <input
                      class="form__field"
                      type="text"
                      v-bind:value="from"
                      data-type="from"
                      @input="getLocationsFrom($event.target.value)"
                      @focus="showTypeheadMenu('from')"
                      @blur="hideTypeheadMenu"
                      autocomplete="off"
                      placeholder="from"
                    />
                    <div
                      class="typeahead-menu typeahead-empty"
                      :class="{'typeahead-open' : fromOrTo === 'from'}"
                    >
                      <div class="typeahead-dataset typeahead-dataset-departureLocations">
                        <div
                          class="typeahead-suggestion typeahead-selectable"
                          data-type="select-from"
                          v-for="(item, index) of locationsListFrom" :key="index"
                          @click="selectLocation(item)"
                        >{{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="$v.from.$dirty && !$v.from.required" class="form__field-invalid" style="color: #000">
                    This field is required
                  </div>
                </label>
                <div class="form__arrows">
                  <span class="form__arrows-one"><SvgIcon name="right-long-arrow"/></span>
                  <span class="form__arrows-double"><SvgIcon name="double-long-arrows"/></span>
                </div>
                <label class="form__label form__label--text">
                  <div class="form__caption">Arrival</div>
                  <div class="form__field-wrapper">
                    <div class="form__icon">
                      <SvgIcon name="arrival"/>
                    </div>
                    <input
                      class="form__field"
                      type="text"
                      v-bind:value="to"
                      data-type="to"
                      @input="getLocationsTo($event.target.value)"
                      @focus="showTypeheadMenu('to')"
                      @blur="hideTypeheadMenu"
                      autocomplete="off"
                      placeholder="to"
                    />
                    <div
                      class="typeahead-menu typeahead-empty"
                      :class="{'typeahead-open' : fromOrTo === 'to'}"
                    >
                      <div class="typeahead-dataset typeahead-dataset-departureLocations">
                        <div
                          class="typeahead-suggestion typeahead-selectable"
                          data-type="select-to"
                          v-for="(item, index) of locationsListTo" :key="index"
                          @click="selectLocation(item)"
                        >{{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="$v.to.$dirty && !$v.to.required" class="form__field-invalid" style="color: #000">
                    This field is required
                  </div>
                </label>
              </div>
              <div class="form__labelGroup form__labelGroup--until">
                <div class="form__caption">List until:</div>
                <!--                <label class="form__label form__label&#45;&#45;radio">-->
                <!--                  <input-->
                <!--                    class="form__radio"-->
                <!--                    type="radio"-->
                <!--                    v-model="listUntil"-->
                <!--                    :value="nowDate.getFullYear() - 1"-->
                <!--                  />-->
                <!--                  <i class="form__radio-icon"></i>-->
                <!--                  <span class="form__radio-box">{{ nowDate.getFullYear() - 1 }}</span>-->
                <!--                </label>-->
                <label class="form__label form__label--radio">
                  <input
                    class="form__radio"
                    type="radio"
                    v-model="listUntil"
                    :value="nowDate.getFullYear()"
                  />
                  <i class="form__radio-icon"></i>
                  <span class="form__radio-box">{{ nowDate.getFullYear() }}</span>
                </label>
                <label class="form__label form__label--radio">
                  <input
                    class="form__radio"
                    type="radio"
                    v-model="listUntil"
                    :value="nowDate.getFullYear() + 1"
                  />
                  <i class="form__radio-icon"></i>
                  <span class="form__radio-box">{{ nowDate.getFullYear() + 1 }}</span>
                </label>
                <label class="form__label form__label--radio">
                  <input
                    class="form__radio"
                    type="radio"
                    v-model="listUntil"
                    value="Forever"
                  />
                  <i class="form__radio-icon"></i>
                  <span class="form__radio-box">Forever</span>
                </label>
              </div>
              <div
                class="form__labelGroup form__labelGroup--price">
                <div class="form__caption">Price up to:</div>
                <label class="form__label form__label--text price">
                  <div class="form__field-wrapper">
                    <input
                      class="form__field"
                      type="text"
                      v-model="price"
                      autocomplete="off"
                      placeholder="enter price"
                    />
                  </div>
                </label>
              </div>
              <div class="form__labelGroup form__labelGroup--options">
                <label class="form__label form__label--checkbox">
                  <input
                    class="form__checkbox"
                    type="checkbox"
                    v-model="options"
                    value="aircraft"
                  />
                  <span class="form__checkbox-box">Charter aircraft options</span>
                </label>
                <label class="form__label form__label--checkbox">
                  <input
                    class="form__checkbox"
                    type="checkbox"
                    v-model="options"
                    value="seat"
                  />
                  <span class="form__checkbox-box">Charter space options</span>
                </label>
              </div>
            </div>
            <div class="form__right">
              <div class="form__periods js-tabBox">
                <div class="form__periods-title">Periods</div>
                <div class="form__periods-nav" ref="specificDates">
                  <div
                    class="form__periods-nav-item js-tabNavItem"
                    data-id="specific-dates"
                    @click="tabActivate"
                  >
                    Specific dates
                  </div>
                  <div
                    class="form__periods-nav-item js-tabNavItem"
                    data-id="days"
                    @click="tabActivate"
                  >
                    Days
                  </div>
                </div>
                <div class="form__periods-content">
                  <div class="form__periods-content-item js-tabContentItem" data-id="specific-dates">
                    <div class="form__labelGroup form__labelGroup--date">
                      <label class="form__label form__label--date">
                        <div v-if="type === 'one way'" class="form__caption js-date-caption">
                          From date:
                        </div>
                        <div v-else class="form__caption js-date-caption">
                          From-To dates:
                        </div>
                        <div class="form__field-wrapper">
                          <a-date-picker
                            v-if="type === 'one way'"
                            placeholder="select date"
                            :showToday="false"
                            :allowClear="false"
                            v-model="dateForm"
                            format="MM/DD/YYYY"
                            :getCalendarContainer="getPickerContainer"
                          />
                          <a-range-picker
                            v-else
                            :placeholder="['select date', '']"
                            :separator="dateFormRange && dateFormRange.length > 0 ? ' - ' : ' '"
                            :allowClear="false"
                            v-model="dateFormRange"
                            format="MM/DD/YYYY"
                            :getCalendarContainer="getPickerContainer"
                          />
                          <div class="form__icon">
                            <SvgIcon name="calendar"/>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="form__periods-content-item js-tabContentItem" data-id="days">
                    <div class="form__labelGroup form__labelGroup--departure" ref="departureDays">
                      <div class="form__caption">LEG ONE</div>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="sun"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Sun</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="mon"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Mon</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="tues"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Tues</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="wed"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Wed</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="thur"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Thur</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="fri"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Fri</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="departureDays"
                          value="sat"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Sat</span>
                      </label>
                    </div>
                    <div class="form__labelGroup form__labelGroup--return" ref="returnDays"
                         v-if="type === 'round trip'">
                      <div class="form__caption">LEG TWO</div>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="sun"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Sun</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="mon"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Mon</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="tues"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Tues</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="wed"/>
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Wed</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="thur"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Thur</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="fri"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Fri</span>
                      </label>
                      <label class="form__label form__label--checkbox">
                        <input
                          class="form__checkbox"
                          type="checkbox"
                          v-model="returnDays"
                          value="sat"
                        />
                        <i class="form__checkbox-icon"></i>
                        <span class="form__checkbox-box">Sat</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__button-wr">
                <div class="form__label form__label--button">
                  <div class="spinner"></div>
                  <button class="form__button form__button--add form__button">
                    <span class="icon"><SvgIcon name="checked"/></span>
                    <span class="text">Add route</span>
                  </button>
                </div>
                <div class="form__label form__label--button">
                  <div
                    class="form__button form__button--clear js-close-alert-form form__button"
                    @click="hideForm"
                  >
                    <span class="icon"><SvgIcon name="close"/></span>
                    <span class="text">Clear route</span>
                  </div>
                </div>
              </div>
          </form>
        </div>

        <div class="alertsDashboard__list">
          <div v-if="!routes.length" class="alertsDashboard__list-empty js-alert-empty" style="color: black">No alerts</div>
          <div
            v-for="item of routes"
            :key="item.id"
            class="alertsDashboard__list-item js-alert-item"
            :class="{'alertPause' : item.stopped}"
            @mouseenter="showPanel"
            @mouseleave="hidePanel"
          >
            <div class="alertsDashboard__list-item-info">
              <div class="alertsDashboard__list-item-info-row">
                <div class="alertsDashboard__list-item-route">
                  <div class="alertsDashboard__list-item-route-title">{{ item.departureName }}</div>
                  <div class="alertsDashboard__list-item-route-direction">
                    <SvgIcon name="double-long-arrows"/>
                  </div>
                  <div class="alertsDashboard__list-item-route-title">{{ item.arrivalName }}</div>
                </div>
                <div class="alertsDashboard__list-item-flights">
                  <div v-if="!item.stopped" class="alertsDashboard__list-item-flights-text"><span v-if="userType !==4">Created On: </span>{{ moment(item.createdOn).format('M/D/YYYY HH:mm z') }}
                    EST <span @click="showFlyerDetailsPopup(item.flyer)"
                              v-if="userType===4"> {{ " - " + item.flyer.firstName + ' ' + item.flyer.lastName }} </span>
                  </div>
                  <div v-else class="alertsDashboard__list-item-flights-pause">
                    <SvgIcon name="pause"/>
                  </div>
                </div>
              </div>
              <div class="alertsDashboard__list-item-info-row">
                <div class="alertsDashboard__list-item-text">
                  All {{
                    item.bookingType === 2 ? 'Charter Aircraft' : item.bookingType === 4 ? 'Charter Spaces' : item.bookingType === 64 ? 'Charter Aircraft and Charter Spaces' : 'undefined'
                  }} On {{
                    item.dates ? moment(item.dates[0].departureDate).format('M/D/YYYY') : 'Selected days of week'
                  }} | Direction: {{ item.direction === 2 ? 'One-way' : 'Round-trip' }} | List Until:
                  {{ item.listUntil === null ? 'Permanent' : item.listUntil }} <br>
                  {{item.dates ? '' : ('Departure Days: ' + (item.departureDays === '' ? 'Any' : showDepartureDays(item.departureDays)) + ' | Return Days: ' + (item.returnDays === '' ? 'Any' : showDepartureDays(item.returnDays)))}}
                </div>
                <div class="alertsDashboard__list-item-price">Up to ${{ item.maxPrice }}</div>
              </div>
            </div>
            <div class="js-alert-nav">
              <div class="alertsDashboard__list-item-nav">
                <div class="alertsDashboard__list-item-nav-inner">
                  <div class="alertsDashboard__list-item-nav-item js-alert-pause">
                    <span class="alertsDashboard__list-item-nav-item-icon">
                      <SvgIcon name="pause" v-if="!item.stopped"/>
                      <SvgIcon name="play" v-else/>
                    </span>
                    <span
                      class="alertsDashboard__list-item-nav-item-text js-alert-pause-text"
                      v-if="!item.stopped"
                      @click="changeWatching(item.id)"
                    >Pause watching</span>
                    <span
                      class="alertsDashboard__list-item-nav-item-text js-alert-pause-text"
                      v-else
                      @click="changeWatching(item.id)"
                    >Continue watching</span>
                  </div>
                  <div class="alertsDashboard__list-item-nav-item js-alert-delete" @click="showModal(item.id)">
                    <span class="alertsDashboard__list-item-nav-item-icon"><SvgIcon name="delete"/></span>
                    <span class="alertsDashboard__list-item-nav-item-text">Delete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <QuestionPopup
        :name="'QuestionPopupRoute'"
        :title="'The route will be removed'"
        :text="'Are you sure?'"
        @remove="remove"
      />
    </vue-scroll>
    <DashboardPreloader/>
    <FlyerDetailsPopup/>
    <InfoPopup/>
  </div>
</template>

<script>
import {reportsAPI} from '@/api/reports'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import QuestionPopup from '@/components/common/modals/QuestionPopup'
import {Tabs} from '@/js/plugins/tabs'
import moment from 'moment'
import {required} from 'vuelidate/lib/validators'
import DashboardPreloader from '@/views/dashboard/common/DashboardPreloader/DashboardPreloader'
import '../../admin/SignUpDashboard/SignUpDashboard.styl'
import './Alerts.styl'
import FlyerDetailsPopup from '@/components/common/modals/FlyerDetailsPopup'
import InfoPopup from '@/components/common/modals/InfoPopup';

export default {
  props: ['dashboardType'],
  name: 'Alerts',
  data: () => ({
    type: 'one way',
    from: '',
    to: '',
    fromId: '',
    toId: '',
    fromOrTo: null,
    locationsListFrom: [],
    locationsListTo: [],
    listUntil: 'Forever',
    price: '',
    options: [],
    dateForm: null,
    dateFormRange: null,
    departureDays: [],
    returnDays: [],
    currentRoute: null,
    tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    hideAlertBtn: false,
    formOpened: false,
  }),
  validations: {
    from: {required},
    to: {required},
  },
  computed: {
    routes () {
      return this.$store.state.routeAlerts.routeAlerts
    },
    userType () {
      return this.$store.state.auth.user.type;
    },
    nowDate () {
      return new Date()
    },
  },
  watch: {
    type () {
      this.dateForm = null
      this.dateFormRange = null
    },
  },
  async created () {
    const userType = this.$store.state.auth.user.type;
    if (userType === 4) {
      await this.$store.dispatch('getRouteAlertsAdmin')
    } else {
      await this.$store.dispatch('getRouteAlerts')
    }

    const preloader = document.querySelector('.dashboardPreloader')
    preloader.classList.add('dashboardPreloader--hide')
  },
  mounted () {
    this.$root.fields.changeRadio('.form__radio')
    this.$root.fields.changeChecked('.form__checkbox')
    this.tabs.addActiveForMouted()
  },
  methods: {
    showFlyerDetailsPopup (account) {
      this.$modal.show('FlyerDetailsPopup', {additionalInfo: account})
    },
    showDepartureDays (days) {
      const daysValue = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      let daysStr = '';

      if (days === '0000000') {
        daysStr = 'Any'
      } else if (days.length > 0) {
        for (let i = 0; i < days.length; i++) {
          const day = days[i]
          if (day === '1') {
            daysStr += `${daysValue[i]}, `
          }
        }
        daysStr = daysStr.slice(0, -2);
      }
      return daysStr;
    },
    moment,
    async getLocationsFrom (value) {
      this.from = value
      const that = this

      if (this.timeout != null) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async function () {
        this.timeout = null

        const data = {
          query: that.from,
          type: 'from',
        }

        const result = await that.$store.dispatch('searchLocationsRoutes', data)

        if (result) {
          that.locationsListFrom = result.data.slice(0, 4)
        } else {
          that.locationsListFrom = []
          that.fromId = ''
        }

        that.fromOrTo = 'from'
        if (that.from === '' || !that.locationsListFrom.length) {
          that.fromOrTo = null
        }
      }, 500)
    },
    async getLocationsTo (value) {
      this.to = value
      const that = this

      if (this.timeout != null) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async function () {
        this.timeout = null

        const data = {
          query: that.to,
          type: 'to',
        }

        const result = await that.$store.dispatch('searchLocationsRoutes', data)

        if (result) {
          that.locationsListTo = result.data.slice(0, 4)
        } else {
          that.locationsListTo = []
          that.toId = ''
        }

        that.fromOrTo = 'to'
        if (that.to === '' || !that.locationsListTo.length) {
          that.fromOrTo = null
        }
      }, 500)
    },
    showTypeheadMenu (type) {
      if (type === 'from' && this.locationsListFrom.length) {
        this.fromOrTo = 'from'
      } else if (type === 'to' && this.locationsListTo.length) {
        this.fromOrTo = 'to'
      } else {
        this.fromOrTo = null
      }
    },
    hideTypeheadMenu () {
      const that = this
      setTimeout(function () {
        that.fromOrTo = null
      }, 200)
    },
    selectLocation (item) {
      const that = this
      if (event.target.getAttribute('data-type') === 'select-from') {
        this.fromId = item.id
        this.from = item.name
      } else {
        this.toId = item.id
        this.to = item.name
      }
      setTimeout(function () {
        that.fromOrTo = null
      }, 200)
    },
    showPanel (event) {
      const html = document.querySelector('html')
      if (!html.classList.contains('d-mobile')) {
        const item = event.currentTarget.querySelector('.js-alert-nav')
        this.$root.animations.slideDown(item)
      }
    },
    hidePanel (event) {
      const html = document.querySelector('html')
      if (!html.classList.contains('d-mobile')) {
        const item = event.currentTarget.querySelector('.js-alert-nav')
        this.$root.animations.slideUp(item)
      }
    },
    showModal (item) {
      this.$modal.show('QuestionPopupRoute')
      this.currentRoute = item
    },
    async remove () {
      await this.$store.dispatch('deleteRouteAlert', this.currentRoute)
    },
    tabActivate (event) {
      this.tabs.addActiveForClick(event.currentTarget)
      document.querySelectorAll('.js-acc-content').forEach((content) => {
        this.$root.animations.slideUp(content)
      })
    },
    getPickerContainer (trigger) {
      return document.querySelector('.whitePicker')
    },
    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return false
      }
      let specDate = null
      let bookingType = 0;

      if (this.$refs.specificDates) {
        const specificDates = this.$refs.specificDates.querySelectorAll('.form__periods-nav-item')
        specificDates.forEach(item => {
          if (item.classList.contains('active')) {
            specDate = item.getAttribute('data-id')
          }
      })
      }

      // DATES - begin
      const date = {}
      if (this.dateForm !== null) {
        const firstDate = moment(this.dateForm).format('MMM DD, YYYY')
        date[firstDate] = null
      } else if (this.dateFormRange !== null) {
        const firstDate = moment(this.dateFormRange[0]).format('MMM DD, YYYY')
        const secondDate = moment(this.dateFormRange[1]).format('MM/DD/YYYY')
        date[firstDate] = secondDate
      } else {
        const firstDate = moment().format('MMM DD, YYYY')
        date[firstDate] = null
      }
      // DATES - end

      // DEPARTURE/RETURNDays DAYS - begin
      const departureDaysArr = []
      if (this.$refs.departureDays) {
        const departureDaysElems = this.$refs.departureDays.querySelectorAll('.form__checkbox')
        departureDaysElems.forEach(item => {
          if (item.checked) {
            departureDaysArr.push(1)
          } else {
            departureDaysArr.push(0)
          }
        })
      }

      const returnDaysArr = []
      if (this.$refs.returnDays) {
        const returnDaysElems = this.$refs.returnDays.querySelectorAll('.form__checkbox')
        returnDaysElems.forEach(item => {
          if (item.checked) {
            returnDaysArr.push(1)
          } else {
            returnDaysArr.push(0)
          }
        })
      }
      // DEPARTURE/RETURNDays DAYS - end

      console.log(this.options)
      // BOOKING TYPE
      if (this.options.length === 2) {
        bookingType = 64
      } else {
        if (this.options[0] === 'aircraft') {
          bookingType = 2
        } else if (this.options[0] === 'seat') {
          bookingType = 4
        } else {
          this.$modal.show('InfoPopup', {
            title: 'Submission failed',
            text: 'Please select either or both options, CHARTER AIRCRAFT OPTIONS and/or CHARTER SPACES OPTIONS',
          })
          return
        }
      }

      const data = {
        departureId: String(this.fromId),
        arrivalId: String(this.toId),
        maxPrice: this.price,
        listUntil: this.listUntil === 'Forever' ? null : this.listUntil,
        bookingType: bookingType,
        specificDates: specDate === 'specific-dates',
        dates: date,
        direction: this.type === 'one way' ? String(2) : String(1),
        departureDays: departureDaysArr.join(''),
        returnsDays: returnDaysArr.join(''),
      }

      console.log(data)

      await this.$store.dispatch('addRouteAlert', data)

      this.hideForm()
    },
    showForm (event) {
      const item = event.currentTarget.closest('.alertsDashboard').querySelector('.js-alert-form')
      this.$root.animations.slideDown(item)
      this.hideAlertBtn = true
      item.addEventListener('transitionend', () => {
        this.formOpened = true
        item.removeEventListener('transitionend', null)

        document.querySelector('.js-alert-form').style.height = 'auto'
      }, {once: true})
    },
    hideForm () {
      const item = this.$refs.alertForm
      this.formOpened = false
      this.$root.animations.slideUp(item)

      item.addEventListener('transitionend', () => {
        this.hideAlertBtn = false
        item.removeEventListener('transitionend', null)
      }, {once: true})

      document.querySelectorAll('.form__label--checkbox').forEach(item => {
        item.className.includes('checked') && item.classList.remove('checked')
      })

      // clear form
      this.from = ''
      this.to = ''
      this.fromId = ''
      this.toId = ''
      this.fromOrTo = null
      this.locationsListFrom = []
      this.locationsListTo = []
      this.price = ''
      this.dateForm = null
      this.dateFormRange = null
      this.departureDays = []
      this.returnDays = []
      this.options = []
    },
    async changeWatching (id) {
      await this.$store.dispatch('toggleRouteAlert', id)
    },
    async exportToExcel () {
      try {
        const {data} = await reportsAPI.routeAlerts();

        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', data]);
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = 'route-alerts_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (e) {

      }
    },
  },
  components: {
    InfoPopup,
    SvgIcon,
    QuestionPopup,
    DashboardPreloader,
    FlyerDetailsPopup,
  },
}
</script>
